import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SequenceTicketRapport = _resolveComponent("SequenceTicketRapport")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.modelValue,
    onShow: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadWithSequenceRapport(_ctx.sequenceId))),
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    header: 
      _ctx.$t('rapport.sequenceDetails', { number: _ctx.result?.sequenceGet?.number })
    ,
    position: "top",
    style: { width: '400px' },
    breakpoints: { '960px': '75vw', '641px': '84vw' }
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('close'),
        icon: "pi pi-times",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', false))),
        class: "p-button-warning"
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.printElement('print-rapport'))),
        loading: _ctx.loading,
        disabled: _ctx.loading,
        label: _ctx.$t('pos.print'),
        icon: "pi pi-print",
        autofocus: ""
      }, null, 8, ["loading", "disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_SequenceTicketRapport, { result: _ctx.result }, null, 8, ["result"])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}