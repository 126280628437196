
import { defineComponent, reactive } from "vue";
import RapportFilter from "./RapportFilter.vue";
import { useSequencesRapport } from "@/graphql/rapport/sequences.rapport";
import { activeActivity } from "@/plugins/i18n";
import SequenceTicketReprint from "@/components/sequence/SequenceTicketReprint.vue";
import { CONSTANTS } from "@/graphql/utils/utils";

export default defineComponent({
  name: "SequencesRapport",
  components: { SequenceTicketReprint, RapportFilter },
  setup() {
    const reprintDialog = reactive({
      sequenceId: "",
      visible: false,
    });
    function showReprint(sequenceId: string) {
      reprintDialog.sequenceId = sequenceId;
      reprintDialog.visible = true;
    }
    return {
      ...useSequencesRapport(),
      currencySymbol: activeActivity.value.currencySymbol,
      rowsPerPageOptions: CONSTANTS.rowsPerPageOptions,
      reprintDialog,
      showReprint,
    };
  },
  created() {
    this.initData();
  },
});
