
import { defineComponent } from "vue";
import SequenceTicketRapport from "@/components/sequence/SequenceTicketRapport.vue";
import { useSequenceRapport } from "@/graphql/sequence/sequence-closure-rapport";
import { printElement } from "@/graphql/ticket/ticket.printer";

export default defineComponent({
  name: "SequenceTicketReprint",
  components: { SequenceTicketRapport },
  props: ["sequenceId", "modelValue"],
  emits: ["update:modelValue"],
  setup() {
    const { loading, result, loadWithSequenceRapport } = useSequenceRapport();
    return {
      loading,
      result,
      loadWithSequenceRapport,
      printElement,
    };
  },
});
