import {
  ComparativeRapportInput,
  QuerySequencesRapportArgs,
  SequencesRapportOutput,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { useLazyQuery, useResult } from "@vue/apollo-composable";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { reactive, ref } from "vue";
import moment from "moment";
import { activeActivity } from "@/plugins/i18n";
import { printRapport } from "@/components/rapport/printer";
import { useI18n } from "vue-i18n";
import { formatNumber } from "@/graphql/utils/utils";

type SequencesRapportData = {
  sequencesRapport: SequencesRapportOutput[];
};

const SEQUENCES_RAPPORT = gql`
  query SequencesRapport($input: ComparativeRapportInput!) {
    sequencesRapport(input: $input) {
      terminal
      sequenceId
      sequence
      openedAt
      closedAt
      cashingOut
      income
      total
      discount
    }
  }
`;
const FILTER = {
  terminal: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.CONTAINS }],
  },
  sequence: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.CONTAINS }],
  },
  openedAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  closedAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  income: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  credit: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  total: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  cashingOut: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  balance: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

type SequenceRapportRow = SequencesRapportOutput & {
  credit: number;
  balance: number;
};

export const useSequencesRapport = () => {
  const filters = ref({ ...FILTER });
  const date = new Date();
  const { t, d } = useI18n();

  const input = reactive<ComparativeRapportInput>({
    activityId: activeActivity.value.id,
    startAt: moment(date).add(-10, "days").toDate(),
    endAt: date,
    period: null,
  });
  const { loading, load, result } = useLazyQuery<
    SequencesRapportData,
    QuerySequencesRapportArgs
  >(SEQUENCES_RAPPORT);
  const sequences = useResult<
    SequencesRapportData,
    SequenceRapportRow[],
    SequenceRapportRow[]
  >(result, [], (res) =>
    res.sequencesRapport.map((e) => ({
      ...e,
      openedAt: new Date(e.openedAt),
      closedAt: e.closedAt ? new Date(e.closedAt) : e.closedAt,
      balance: e.total - e.cashingOut,
      credit: e.total > e.income ? e.total - e.income : 0,
    }))
  );
  function refresh() {
    Object.assign(filters.value, FILTER);
  }
  function initData() {
    void load(
      SEQUENCES_RAPPORT,
      {
        input: { ...input },
      },
      { fetchPolicy: "no-cache" }
    );
  }

  function print() {
    printRapport(`<table>
      <thead>
        <tr>
          <th class="p-text-uppercase" colspan="9">
            <h2 class="p-pt-3">${t("rapport.tab5")}</h2>
          </th>
        </tr>
        <tr>
          <th colspan="3" style="border-right: unset">
             ${t("rapport.period")}
          </th>
          <th colspan="3" class="p-no-border">
            ${t("rapport.from")}
            ${d(input.startAt, "long")}
          </th>
          <th colspan="3" style="border-left: unset">
            ${t("rapport.to")}
            ${d(input.endAt, "long")}
          </th>
        </tr>
        <tr>
          <th class="p-text-left">${t("pos.terminal")}</th>
          <th>${t("pos.sequence")}</th>
          <th>${t("pos.opening")}</th>
          <th>${t("pos.closure")}</th>
          <th>${t("rapport.payedIncome")}</th>
          <th>${t("rapport.unpayedIncome")}</th>
          <th>${t("rapport.totalIncome")}</th>
          <th>${t("rapport.cashingOut")}</th>
          <th>${t("rapport.balance")}</th>
        </tr>
      </thead>
      <tbody>
        ${sequences.value
          .map(
            (data) => `<tr>
          <td class="p-text-left">
            ${data.terminal}
          </td>
          <td>
            ${data.sequence}
          </td>
          <td>${d(data.openedAt, "long")}</td>
          <td>${data.closedAt && d(data.closedAt, "long")}</td>
          <td>
            ${formatNumber(data.income)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.credit)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.total)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.cashingOut)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td class="p-text-right">
            ${formatNumber(data.balance)}
            ${activeActivity.value.currencySymbol}
          </td>
        </tr>`
          )
          .join("")}
      </tbody>
      <tfoot>
        <tr>
          <th class="p-text-uppercase p-text-left" colspan="4">
            ${t("rapport.total")}
          </th>
          <th>
            ${formatNumber(filteredTotals.income)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.credit)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.total)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.expense)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.balance)}
            ${activeActivity.value.currencySymbol}
          </th>
        </tr>
      </tfoot>
    </table>`);
  }

  const filteredTotals = reactive({
    income: 0,
    expense: 0,
    balance: 0,
    total: 0,
    credit: 0,
  });

  function onFilter(event: any) {
    filteredTotals.income = 0;
    filteredTotals.expense = 0;
    filteredTotals.balance = 0;
    filteredTotals.total = 0;
    filteredTotals.credit = 0;
    (event.filteredValue as SequenceRapportRow[]).forEach((row) => {
      filteredTotals.income += row?.income || 0;
      filteredTotals.expense += row?.cashingOut || 0;
      filteredTotals.balance += row?.balance || 0;
      filteredTotals.total += row?.total || 0;
      filteredTotals.credit += row?.credit || 0;
    });
  }
  return {
    loading,
    sequences,
    input,
    initData,
    refresh,
    filters,
    print,
    filteredTotals,
    onFilter,
    formatNumber,
  };
};
